<template>
	<div>
		<h2 class="text-h5 text-sm-h5 mb-6">My Profile</h2>
		<v-form lazy-validation ref="form" v-model="valid">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense readonly label="Username" v-model="object.username"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Email*" :rules="rules.email" :error-messages="errors.email" v-model="object.email"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="First Name*" :rules="rules.first_name" :error-messages="errors.first_name" v-model="object.first_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Last Name*" :rules="rules.last_name" :error-messages="errors.last_name" v-model="object.last_name"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <small class="red--text">* Required field</small>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" :loading="loading" @click="save">Save Changes</v-btn>
                </v-col>
            </v-row>
		</v-form>
	</div>
</template>


<script>

import { getLocalStorage } from '@/utils/auth'

export default {
	name: 'ProfileUpdateForm',
	data() {
		return {
			valid: true,
			loading: false,
            object: {
                username: '',
                first_name: '',
                last_name: '',
                email: ''
            },
			rules: {
				first_name: [
					(value) => !!value || 'First name is required',
					(value) => value && value.length >= 3 && value.length <= 100 || 'First name must be between 3 and 100 characters'
				],
				last_name: [
					(value) => !!value || 'Last name is required',
					(value) => value && value.length >= 3 && value.length <= 100 || 'Last name must be between 3 and 100 characters'
				],
				email: [
					(value) => !!value || 'Email is required',
					(value) => value && /.+@.+\..+/.test(value) || 'Email is invalid'
				]
			},
			errors: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		}
	},
	mounted: function() {
        this.updateObject()
	},
	methods: {
		save: function() {
			if(this.form.validate()){
				this.loading = true
                const object = { first_name: this.object.first_name, last_name: this.object.last_name, email: this.object.email }
				this.$store.dispatch('auth/updateProfile', object).then((response) => {
                    this.loading = false
                    this.reset()
                    this.$emit('updated', {message: response.message})
                }).catch((errors) => {
                    this.errors = errors.errors
                    this.loading = false
                })
			}
		},
		updateObject: function() {
            if(this.$store.getters.auth.username){
                const auth = this.$store.getters.auth
                this.object = { username: auth.username, first_name: auth.first_name, last_name: auth.last_name,
                                email: auth.email }
            }else{
                let user = getLocalStorage('blink-data', 'user')
                if(user){
                    this.object = user
                }
            }
        },
        reset: function() {
            this.form.resetValidation()
        }
	}
}

</script>