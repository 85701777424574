<template>
	<div>
		<p class="text-h5 text-sm-h5 mb-6">Change Password</p>
		<v-form lazy-validation ref="form" v-model="valid">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable type="password" label="New Password*" maxlength="20" :rules="rules.password" :error-messages="errors.password" v-model="object.password"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable type="password" label="Confirm New Password*" maxlength="20" :rules="rules.confirm_password" :error-messages="errors.confirm_password" v-model="object.confirm_password"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <small class="red--text">* Required field</small>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" :loading="loading" @click="save">Change Password</v-btn>
                </v-col>
            </v-row>
		</v-form>
	</div>
</template>


<script>

export default {
	name: 'PasswordUpdateForm',
	data() {
		return {
			valid: true,
			loading: false,
            object: {
                password: '',
                confirm_password: ''
            },
			rules: {
				password: [
					(value) => !!value || 'Password is required',
					(value) => value.length >= 6 && value.length <= 20 || 'New password must be between 6 and 20 characters'
				],
				confirm_password: [
					(value) => !!value || 'Confirm password is required',
					(value) => value.length >= 6 && value.length <= 20 || 'New confirm password must be between 6 and 20 characters',
					(value) => this.object.password && this.object.password === value || 'New confirm password does not match'
				]
			},
			errors: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		}
	},
	methods: {
        save: function() {
			if(this.form.validate()){
				this.loading = true
				this.$store.dispatch('auth/updatePassword', this.object).then((response) => {
                    this.loading = false
                    this.reset()
                    this.$emit('updated', {message: response.message})
                }).catch((errors) => {
                    this.errors = errors.errors
                    this.loading = false
                })
			}
		},
		reset: function() {
            this.object = { password: '', confirm_password: '' }
            this.form.resetValidation()
		}
	}
}

</script>