<template>
	<div>
        <profile-update-form @updated="openMessageNotifier"></profile-update-form>
        <password-update-form @updated="openMessageNotifier"></password-update-form>
        <message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import MessageNotifier from '../../../shared/MessageNotifier.vue'
import ProfileUpdateForm from './ProfileUpdateForm.vue'
import PasswordUpdateForm from './PasswordUpdateForm.vue'

export default {
	name: 'ProfileForm',
	components: {
        messageNotifier: MessageNotifier,
        profileUpdateForm: ProfileUpdateForm,
        passwordUpdateForm: PasswordUpdateForm
	},
	computed: {
        notifier() {
            return this.$refs.notifier
        }
	},
	methods: {
        openMessageNotifier: function(item) {
            this.notifier.updateText(item.message)
            this.notifier.open()
        }
	}
}

</script>